import {graphql, Link, StaticQuery} from "gatsby"
import React from "react"
import kebabCase from "lodash/kebabCase"
import Img from 'gatsby-image'

import logo from "../images/Logoico.png"
import newsletter from "../images/newsletter.png"

import FooterStyles from "./footer.module.css"

const Footer = () => {

  return(
    <footer>
      {/*<Newsletter/>*/}
      <div className={FooterStyles.footerLogo}><img src={logo} alt="made by RekaS" className={FooterStyles.logo} /></div>
      <Tags />
      <div className={FooterStyles.ttml}>
        <p>
          <span>talk to me:</span>
          <span>madebyrekas@gmail.com</span>
        </p>
        <p>
          <span>Tag me:</span>
          <a href="https://instagram.com/madebyrekas" target={'_blank'} rel={'noopener noreferrer'}>@madebyrekas</a>
        </p>
      </div>
      <ul className={FooterStyles.menu}>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><Link to="/policies">Policies</Link></li>
      </ul>
      <span className={FooterStyles.copyright}>copyright 2020 madebyrekas</span>
    </footer>
)
}

export default Footer

const Newsletter = props => (
  <div className={FooterStyles.newsletter}>
    <Img src={newsletter} alt="newsletter" />
      <form>
        <h2>Newsletter</h2>
        <input type="text" name="name" placeholder="Name"/>
        <input type="text" name="email" placeholder="Email"/>
        <input type="submit" name="submit" value="Sign up"/>
      </form>
  </div>
)
const Tags = props => (
  <div className={FooterStyles.tags}>
    <h2>Tags</h2>
    <StaticQuery
      query={graphql`
          {
            allMarkdownRemark {
              group(field: frontmatter___tags) {
                tag: fieldValue
                totalCount
              }
            }
          }
        `}
      render={data => data.allMarkdownRemark.group.map((g, index) => {
        if(index < 12)
        return(
          <Link to={`/tags/${kebabCase(g.tag)}/`} className={FooterStyles.tag}>{g.tag}</Link>
        )
      })}
    ></StaticQuery>
  </div>
)

// https://www.gatsbyjs.com/docs/adding-tags-and-categories-to-blog-posts/