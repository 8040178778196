import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"

import LogoMobile from "../images/logo-mobile.png"

import headerStyles from "./header.module.css"

const Header = ({ siteTitle }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return(
    <header>
        <Link to="/" id={headerStyles.logo}>
            <img src={LogoMobile} alt="made by Rekas"/>
        </Link>
        <div role='menu-opener' className={`${headerStyles.menuicon} ${navbarOpen ? `${headerStyles.x}` : ""}`} onClick={() => setNavbarOpen(!navbarOpen)}>
          <span aria-hidden="true"></span>
        </div>
        <ul id="main-menu" className={`${headerStyles.menu} ${navbarOpen ? `${headerStyles.open}` : ""}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/categories/patterns">Patterns</Link></li>
          <li><Link to="/about">About</Link></li>
        </ul>
    </header>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
